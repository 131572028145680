<template>
  <v-btn
    variant="tonal"
    color="primary"
    rounded
    size="large"
  >
    <v-icon
      v-if="status === 'saved'"
      color="success"
      class="mr-2"
    >
      mdi-check-circle
    </v-icon>
    <v-progress-circular
      v-else-if="status === 'saving'"
      indeterminate
      color="warning"
      size="24"
      width="2"
      class="mr-2"
    ></v-progress-circular>
    {{ statusText }}
  </v-btn>
</template>

<script>
export default {
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
    hasChanges: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: 'no-changes',
    }
  },
  computed: {
    statusText() {
      switch (this.status) {
        case 'saving':
          return 'Wird gespeichert...'
        case 'saved':
          return 'Gespeichert'
        default:
          return 'Keine Änderungen'
      }
    },
  },
  watch: {
    saving: {
      handler(newValue) {
        if (newValue) {
          this.status = 'saving'
        } else if (this.status === 'saving') {
          this.status = 'saved'
        }
      },
      immediate: true,
    },
    hasChanges: {
      handler(newValue) {
        if (newValue && !this.saving) {
          this.status = 'saving'
        } else if (!newValue && !this.saving) {
          this.status = 'no-changes'
        }
      },
      immediate: true,
    },
  },
}
</script>
